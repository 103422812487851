import {
  AdditionalPaymentConfig,
  PlanOption,
  SubscriptionCardDetails,
  TicketOption,
} from '../ticket-options-types';
import {
  BillingPeriodConfiguration,
  BillingPeriodicity,
} from '@yoimo/interfaces';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable, Subject, filter, map, startWith, takeUntil } from 'rxjs';

import { SubscriptionOption } from '@yoimo/joymo-ui';
import { OfferInstallmentsComponent } from '../../payments/offer-installments/offer-installments.component';

const billingLabels = {
  installmentsHeading: $localize`:@@payInInstallmentsDisclaimerHeading:Pay in installments`,
  installmentsDisclaimer: $localize`:@@payInInstallmentsDisclaimerText:Please note that you cannot cancel individual payments until the entire sum has been paid.`,
  periodicity: {
    MONTHLY: $localize`:@@billedMonthly:Billed once a month`,
    YEARLY: $localize`:@@billedYearly:Billed once a year`,
  } as Record<BillingPeriodicity, string>,
} as const;

/**
 * Displays info about ticket options and allow the user to choose from alternative prices (if available).
 * Works with both single tickets and plans.
 */
@Component({
  selector: 'joymo-ticket-option-selector',
  styleUrls: ['./ticket-option-selector.scss'],
  templateUrl: './ticket-option-selector.component.html',
})
export class TicketOptionSelector implements OnInit, OnDestroy {
  @Output() onUserSelection = new EventEmitter<AdditionalPaymentConfig>();

  @ViewChild(OfferInstallmentsComponent)
  installmentsTpl?: OfferInstallmentsComponent;

  /** Ticket or plan to be displayed */
  @Input() option?: TicketOption | PlanOption;
  /** Allow the parent component to toggle the trial label */
  @Input() hideTrialLabel?: boolean;
  /** Allow the parent component to restrict the use of coupons */
  @Input() disableCoupons?: boolean;
  /** Allow the parent component to restrict selecting of periodicity */
  @Input() disablePeriodicityToggle?: boolean;
  /** Allow the parent component to restrict installments */
  @Input() disableInstallments?: boolean;

  readonly billingLabels = billingLabels;
  readonly form = new FormGroup({
    coupon: new FormControl<string>('', { nonNullable: true }),
    periodicity: new FormControl<BillingPeriodicity | undefined>(undefined, {
      nonNullable: true,
    }),
    useInstallments: new FormControl<boolean>(false, { nonNullable: true }),
  });

  /** Current ticket, if applicable */
  protected ticket?: TicketOption;
  /** Current plan option, if applicable */
  protected plan?: PlanOption;
  /** Alternative prices for the current option */
  protected planOptionsPrices: SubscriptionOption[] = [];
  /** Active periodicity config for a given plan */
  protected activePeriodicity?: BillingPeriodConfiguration;
  protected selectedPlanPrice?: SubscriptionCardDetails['planPrices'][number];

  protected currency?: string;
  /** Price after a coupon is applied */
  protected discountedPrice?: number;
  /** Describe the total payment */
  readonly toBePaid$ = this.getTotalDue$();
  /** Total price if paying with installments */
  protected totalInstallmentPrice?: number;
  protected isTicketRedemptionLimited? = false;

  private destroy$ = new Subject<void>();

  ngOnInit() {
    if (!this.option) {
      throw new Error('Ticket or plan not specified');
    }

    this.handleFormChanges();

    if (this.option.type === 'PLAN') {
      const plan = this.option;
      this.plan = plan;
      this.planOptionsPrices = this.getFormattedPlanPrices(this.option);
      this.currency = plan.planData.priceAlternatives[0].currency;

      this.form.controls.periodicity.valueChanges
        .pipe(takeUntil(this.destroy$), filter(Boolean))
        .subscribe((periodicity) => {
          // Re-init plan data if periodicity changes
          this.form.controls.useInstallments.reset();
          this.form.controls.coupon.reset();
          this.activePeriodicity = this.getActiveBillingPeriodConfig(
            plan,
            periodicity
          );
          this.selectedPlanPrice = this.getSelectedPlanPrice(
            plan.cardDetails.planPrices,
            periodicity
          );
        });

      // Prefill periodicity
      const fallbackPeriodicity =
        plan.cardDetails.planPrices.find((p) => p.highlighted)?.periodicity ||
        plan.cardDetails.planPrices[0].periodicity;
      this.form.controls.periodicity.setValue(
        plan.selectedPeriodicity || fallbackPeriodicity
      );
      return;
    }

    this.form.reset();
    this.ticket = this.option;
    this.isTicketRedemptionLimited = !!(
      this.ticket.ticketData.scope &&
      this.ticket.ticketData.scope.redemptionLimit
    );
    this.currency = this.ticket.optionPrice?.currency;
  }

  private getSelectedPlanPrice(
    prices: PlanOption['cardDetails']['planPrices'],
    periodicity: BillingPeriodicity
  ) {
    return prices.find((p) => p.periodicity === periodicity) || prices[0];
  }

  private getFormattedPlanPrices(plan: PlanOption): SubscriptionOption[] {
    return plan.cardDetails.planPrices.map((planPrice) => {
      return {
        discount: planPrice.discount || '',
        label: planPrice.name,
        value: planPrice.periodicity,
      };
    });
  }

  private getActiveBillingPeriodConfig(
    plan: PlanOption,
    periodicity: BillingPeriodicity
  ): BillingPeriodConfiguration {
    const config = plan.planData.priceAlternatives[0].billingPeriods.find(
      ({ period }) => period === periodicity
    );

    if (!config) {
      throw new Error(`PlanRequiresBillingConfig:${plan.planData.docId}`);
    }

    return config;
  }

  private getTotalDue$(): Observable<number> {
    return this.form.valueChanges.pipe(
      startWith(this.form.value),
      map((form) => {
        if (form.coupon && typeof this.discountedPrice === 'number') {
          return this.discountedPrice;
        }
        if (form.useInstallments && this.activePeriodicity?.installments) {
          return this.activePeriodicity.installments.price;
        }
        if (this.ticket) {
          if (!this.ticket.cardDetails.price) {
            throw new Error('No price set');
          }
          return this.ticket.cardDetails.price.amount;
        }
        if (this.selectedPlanPrice) {
          return this.selectedPlanPrice.priceRaw.amount;
        }
        throw new Error('NotImplemented');
      })
    );
  }

  private handleFormChanges(): void {
    const { useInstallments, coupon } = this.form.controls;

    useInstallments.valueChanges
      .pipe(
        filter((res) => !!res),
        takeUntil(this.destroy$)
      )
      .subscribe((_) => {
        this.form.controls.coupon.reset();
      });

    coupon.valueChanges
      .pipe(
        filter((res) => !!res),
        takeUntil(this.destroy$)
      )
      .subscribe((_) => {
        this.form.controls.useInstallments.reset();
      });

    this.form.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
      this.onUserSelection.emit(value);
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
